import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { RichText } from 'prismic-reactjs'

import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
    query {
      allPrismicHomepage {
        edges {
          node {
            id
            data {
              cta_banner_title {
                raw
              }
              cta_banner_description {
                raw
              }
              cta_button_link {
                url
                type
                uid
              }
              cta_button_label {
                raw
              }
              cta_button_bg_image {
                url
                thumbnails
                alt
                fluid(maxWidth: 1280) {
                  src
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = [
        data.allPrismicHomepage.edges[0].node.data.cta_button_bg_image.fluid,
        `linear-gradient(to right, rgba(17, 45, 78, .7),rgba(17, 45, 78,0.3) 70%)`,
      ].reverse();

      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
          loading="eager"
          fadeIn={false}
        >
          <div className="flex flex-col w-full md:w-1/2 justify-center items-start pt-6 lg:pt-12 pb-12 lg:pb-24 px-6 lg:px-12">
            <h1 className="my-6 text-4xl text-white font-bold leading-tight">
              {RichText.asText(data.allPrismicHomepage.edges[0].node.data.cta_banner_title.raw)}
            </h1>
            <p className="my-4 leading-normal text-lg text-white">
              {RichText.asText(data.allPrismicHomepage.edges[0].node.data.cta_banner_description.raw)}
            </p>
            <div className="invisible md:visible flex mt-4">
              <div className="w-full md:w-auto flex md:flex-none rounded-md shadow">
              <AnchorLink to= "/#contactform" title="Our team">
                <button
                  className="w-full md:w-auto flex md:flex-none items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out shadow-lg"
                >
                  <svg
                    className="-ml-1 mr-2 h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
                  </svg>
                  {RichText.asText(data.allPrismicHomepage.edges[0].node.data.cta_button_label.raw)}
                </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

BackgroundSection.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  min-height: 580px;
  background-position: 50% 30%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default StyledBackgroundSection;
