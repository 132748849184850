import React from "react";
//import audit from "../images/seo-audit.png";

function ServicesOverview() {
  return (
    <div className="py-12">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Übersicht
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Meine Leistungen
          </h3>
          <p className="mt-4 max-w-6xl text-lg mb-4 leading-relaxed text-gray-600 lg:mx-auto">
            Als SEO-Berater unterstütze ich Unternehmen, Einzelunternehmer und
            Start Ups bei der Entwicklung von Strategien und der Umsetzung von
            Maßnahmen zur Suchmaschinenoptimierung. Dabei biete ich individuelle
            und unkomplizierte Hilfe in verschiedensten Bereichen. Von der
            Optimierung der technischen Infrastruktur, bis hin zur
            Keywordsanalyse oder Relaunchbetreuung, bin ich dein
            Ansprechpartner.
          </p>
        </div>

        <div className="mt-4">
          <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="white"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7 13.33a7 7 0 1 1 6 0V16H7v-2.67zM7 17h6v1.5c0 .83-.67 1.5-1.5 1.5h-3A1.5 1.5 0 0 1 7 18.5V17zm2-5.1V14h2v-2.1a5 5 0 1 0-2 0z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Kontinuierliche SEO-Beratung
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ich stehe dir mit allen Leistungen vollständig zur
                    Verfügung, um deine Internetauftritt in den Suchergebnissen
                    ganz nach vorne zu bringen. Ganz flexibel erstellen wir ein
                    individuelles Aufgabenpaket.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="white"
                      viewBox="0 0 512 512"
                    >
                      <defs />
                      <path d="M175.5 145h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM175.5 225h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM175.5 305h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                      <path d="M390.5 274.928V137.98c0-19.77-7.623-38.421-21.465-52.527l-60.862-62.939-.088-.091C294.072 8.173 274.573 0 254.59 0H61.5c-24.813 0-45 20.187-45 45v389c0 24.813 20.187 45 45 45h231.833c21.376 20.433 50.331 33 82.167 33 66.168 0 120-53.383 120-119 0-60.58-45.885-110.729-105-118.072zM46.5 434V45c0-8.271 6.729-15 15-15h193.09c11.974 0 23.658 4.891 32.066 13.418l60.851 62.929.086.089c8.323 8.467 12.907 19.669 12.907 31.545v136.962c-58.562 7.398-104 57.521-104 118.058 0 20.233 5.082 39.3 14.027 56H61.5c-8.271-.001-15-6.73-15-15.001zm329 48c-49.075 0-89-39.925-89-89s39.925-89 89-89c49.626 0 90 39.925 90 89s-40.374 89-90 89z" />
                      <path d="M410.103 357.477c-6.363-5.301-15.822-4.442-21.126 1.921l-25.317 30.381-5.743-11.486c-3.705-7.41-12.715-10.415-20.125-6.708-7.41 3.704-10.413 12.715-6.708 20.124l16 32c2.264 4.528 6.659 7.608 11.687 8.192.579.066 1.156.1 1.731.1 4.421 0 8.654-1.956 11.522-5.397l40-48c5.303-6.366 4.443-15.824-1.921-21.127zM255.5 175h16c8.284 0 15-6.716 15-15s-6.716-15-15-15h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15zM286.5 240c0-8.284-6.716-15-15-15h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15h16c8.284 0 15-6.716 15-15z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    SEO-Audit und Websitenoptimierung
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Hier prüfe ich alle relevanten Onpage– und Offpage-Faktoren
                    deines Internetauftritts und zeige dir in übersichtlicher
                    Form, wo die Schwachstellen liegen und führe die
                    Optimierungen durch.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      className="h-6 w-6"
                    >
                      <defs />
                      <path
                        fill="#fff"
                        d="M44.0002 7.6364v47.9999c0 5.371 3.709 8.3636 7.6364 8.3636 3.6364 0 7.6364-2.5454 7.6364-8.3636V8c0-4.9236-3.6364-8-7.6364-8s-7.6364 3.3927-7.6364 7.6364z"
                      />
                      <g
                        fill="#fff"
                        transform="matrix(.36364 0 0 .36364 -3.2728 -2.909)"
                      >
                        <path d="M75 96v65c0 14.77 10.2 23 21 23 10 0 21-7 21-23V97c0-13.54-10-22-21-22s-21 9.33-21 21z" />
                        <circle cx="41" cy="163" r="21" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Google Analytics & Google Tag Manager
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ich unterstütze dich bei der Einrichtung und Auswertung der
                    wichtigsten Tracking- und Messungstools wie z.B. Google
                    Analytics, damit du jederzeit genau weißt, was auf deiner
                    Website los ist und wie deine Seite performt.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg className="h-6 w-6" fill="white" viewBox="0 0 512 512">
                      <defs />
                      <path d="M197.332 0h-160C16.746 0 0 16.746 0 37.332v96c0 20.5899 16.746 37.336 37.332 37.336h160c20.5899 0 37.336-16.7461 37.336-37.336v-96C234.668 16.746 217.9219 0 197.332 0zm0 0M197.332 213.332h-160C16.746 213.332 0 230.0781 0 250.668v224C0 495.254 16.746 512 37.332 512h160c20.5899 0 37.336-16.746 37.336-37.332v-224c0-20.5899-16.7461-37.336-37.336-37.336zm0 0M474.668 341.332h-160c-20.5899 0-37.336 16.7461-37.336 37.336v96c0 20.586 16.7461 37.332 37.336 37.332h160C495.254 512 512 495.254 512 474.668v-96c0-20.5899-16.746-37.336-37.332-37.336zm0 0M474.668 0h-160c-20.5899 0-37.336 16.746-37.336 37.332v224c0 20.5899 16.7461 37.336 37.336 37.336h160c20.586 0 37.332-16.7461 37.332-37.336v-224C512 16.746 495.254 0 474.668 0zm0 0" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Dashboards & Reportings
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ob Google Data Studio oder Microsoft Power BI, ich helfe dir
                    aus deinen Zahlen die richtigen Ableitungen zu treffen,
                    damit dein Business weiter wachsen kann und optimalem
                    SEO-Traffic nichts mehr im Weg steht.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      className="h-6 w-6"
                      viewBox="0 0 32 32"
                    >
                      <defs />
                      <path d="M2.26 16c0 5.45 3.13 10.145 7.7 12.348L3.478 10.435C2.725 12.174 2.26 14.03 2.26 16zm23.015-.696c0-1.68-.638-2.9-1.16-3.768-.696-1.16-1.333-2.087-1.333-3.246 0-1.275.986-2.435 2.32-2.435h.174C22.84 3.594 19.594 2.26 16 2.26A13.95 13.95 0 004.522 8.463h.87c1.45 0 3.652-.174 3.652-.174.754-.058.812 1.043.116 1.16 0 0-.754.116-1.565.116l4.986 14.84 3.014-8.986-2.145-5.855L12 9.45c-.754-.058-.638-1.16.058-1.16 0 0 2.26.174 3.594.174 1.45 0 3.652-.174 3.652-.174.754-.058.812 1.043.116 1.16 0 0-.754.116-1.565.116L22.84 24.35l1.4-4.58c.58-1.913 1.043-3.246 1.043-4.464zm-9.043 1.913L12.116 29.16c1.217.348 2.55.58 3.884.58 1.623 0 3.13-.3 4.58-.754-.058-.058-.058-.116-.116-.174zM28.058 9.45l.116 1.4c0 1.4-.232 2.957-1.043 4.928l-4.174 12.116c4.058-2.377 6.84-6.783 6.84-11.884-.058-2.377-.696-4.58-1.74-6.55zM16 0C7.188 0 0 7.188 0 16s7.188 16 16 16 16-7.188 16-16S24.812 0 16 0zm0 31.304C7.594 31.304.754 24.464.754 16A15.27 15.27 0 0116 .754 15.27 15.27 0 0131.246 16c0 8.464-6.84 15.304-15.246 15.304z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    WordPress-Optimierung
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ich helfe dir in allen Schritten der WordPress-Einrichtung
                    und Optimierung, von der Pluginauswahl, über das
                    Serverhosting hin bis zur finalen Konfiguration, sodass du
                    SEO-seitig optimal aufgestellt bist.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="white"
                      viewBox="0 0 512 512"
                    >
                      <defs />
                      <circle cx="352" cy="216.122" r="20" />
                      <path d="M452 36H60C26.916 36 0 62.916 0 96v240c0 33.084 26.916 60 60 60h176v40H132v40h248v-40H276v-40h176c33.084 0 60-26.916 60-60V96c0-33.084-26.916-60-60-60zm20 300c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20V96c0-11.028 8.972-20 20-20h392c11.028 0 20 8.972 20 20v240z" />
                      <path d="M332 116.122c-31.55 0-61.22 15.072-79.972 40H133.863L82 206.688v20.02l52.015 49.414h118.014c18.752 24.928 48.422 40 79.972 40 55.141 0 100-44.86 100-100s-44.86-100-100.001-100zm0 160c-21.335 0-41.245-11.502-51.96-30.018l-5.777-9.982h-40.54l-28.947-27.5-26.125 27.5h-28.665l-20.703-19.667 20.854-20.333h124.126l5.777-9.982c10.715-18.516 30.625-30.018 51.96-30.018 33.084 0 60 26.916 60 60s-26.916 60-60 60z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Keywordanalyse
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ich recherchiere, definiere und bewerte die relevanten
                    Keywords für deine Website mit dem Ziel, die Suchbegriffe zu
                    identifizieren, mit denen deine Zielgruppe nach dem Angebot
                    deiner Website sucht.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      viewBox="0 0 512 512"
                      stroke="currentColor"
                    >
                      <defs />
                      <path d="M425.951 89.021C386.864 32.451 324.917 0 256.006 0S125.148 32.451 86.061 89.021c-38.895 56.284-47.876 127.541-24.072 190.496 6.367 17.192 16.488 33.895 30.01 49.547l150.378 176.634c3.401 3.998 8.384 6.302 13.629 6.302 5.245 0 10.228-2.303 13.629-6.302l150.336-176.586c13.582-15.742 23.69-32.427 30.004-49.481 23.852-63.069 14.871-134.326-24.024-190.61zm-9.5 178.072c-4.869 13.158-12.818 26.167-23.613 38.68-.03.03-.06.06-.084.096L256.006 466.487 119.174 305.768c-10.789-12.502-18.738-25.51-23.655-38.794-19.686-52.065-12.215-110.981 19.991-157.592 32.307-46.76 83.519-73.578 140.496-73.578 56.976 0 108.182 26.817 140.49 73.578 32.212 46.611 39.689 105.527 19.955 157.711z" />
                      <path d="M256.006 106.219c-55.276 0-100.252 44.97-100.252 100.252s44.97 100.252 100.252 100.252 100.252-44.97 100.252-100.252c0-55.276-44.976-100.252-100.252-100.252zm0 164.699c-35.536 0-64.448-28.912-64.448-64.448 0-35.536 28.912-64.448 64.448-64.448 35.536 0 64.448 28.912 64.448 64.448s-28.912 64.448-64.448 64.448z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Local SEO
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ich helfe dir mit Maßnahmen und Optmierungen dabei, deine
                    Website optimal in den lokalen Suchergebnissen zu
                    platzieren. Mit ca. 50% lokalen Suchen, sind diese
                    Platzierungen extrem wichtig für deine Sichtbarkeit.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      viewBox="0 0 512 512"
                      stroke="currentColor"
                    >
                      {" "}
                      <defs />
                      <path d="M435.143 129.356c-6.796-6.795-17.463-7.797-25.407-2.384-29.926 20.398-180.03 122.969-196.162 139.1-23.394 23.395-23.394 61.459 0 84.854 11.697 11.696 27.063 17.545 42.427 17.545s30.729-5.849 42.427-17.545c16.131-16.132 118.701-166.236 139.1-196.162 5.411-7.943 4.41-18.611-2.385-25.408zM270.142 322.641c-7.797 7.799-20.486 7.799-28.283 0-7.798-7.797-7.799-20.482-.004-28.28 6.268-6.194 48.885-36.588 101.319-73.035-36.446 52.434-66.84 95.049-73.032 101.315z" />
                      <path d="M92.231 401.523l-24.69 12.044C49.475 381.325 40 345.338 40 308.499c0-26.991 4.977-52.842 14.06-76.683l28.291 13.57c2.79 1.338 5.735 1.972 8.636 1.972 7.453 0 14.608-4.185 18.047-11.355 4.776-9.959.576-21.906-9.384-26.683l-27.932-13.398c34.717-56.62 94.784-96.095 164.283-102.505v30.081c0 11.046 8.954 20 20 20s20-8.954 20-20V93.402c23.828 2.169 46.884 8.237 68.771 18.117 10.065 4.545 21.912.066 26.457-9.999 4.545-10.068.068-21.913-10-26.458C328.063 60.091 292.659 52.499 256 52.499c-68.38 0-132.667 26.628-181.02 74.98C26.629 175.832 0 240.119 0 308.499c0 50.53 14.998 99.674 43.373 142.115 3.822 5.715 10.141 8.886 16.639 8.886 2.954 0 5.946-.655 8.757-2.026l41-20c9.928-4.843 14.05-16.816 9.207-26.744-4.843-9.927-16.817-14.048-26.745-9.207zM489.436 203.271c-4.544-10.067-16.387-14.547-26.458-10-10.067 4.545-14.544 16.39-9.999 26.457C465.601 247.686 472 277.553 472 308.499c0 36.894-9.506 72.939-27.625 105.218l-25.777-12.275c-9.971-4.748-21.906-.515-26.656 9.459-4.749 9.972-.514 21.907 9.459 26.656l42 20c2.763 1.315 5.692 1.944 8.588 1.944 6.5 0 12.82-3.175 16.637-8.886C497.002 408.173 512 359.029 512 308.499c0-36.659-7.592-72.063-22.564-105.228z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Pagespeed-Optimierung
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Du möchtest deine Website schneller machen? Ich analysiere
                    deine Seite und zeige dir auf verständlich Weise, wo
                    potenzial versteckt liegt.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      viewBox="0 0 477.389 477.389"
                      stroke="currentColor"
                    >
                      <defs />
                      <path d="M451.209 68.647c-16.787-16.799-39.564-26.234-63.312-26.226-23.739-.056-46.516 9.376-63.266 26.197L209.056 184.194c-22.867 22.903-31.609 56.356-22.869 87.518 2.559 9.072 11.988 14.352 21.06 11.793 9.072-2.559 14.352-11.988 11.793-21.06-5.388-19.271.018-39.95 14.148-54.118L348.763 92.768c21.608-21.613 56.646-21.617 78.259-.008 21.613 21.608 21.617 56.646.009 78.259L311.456 286.594a55.1594 55.1594 0 01-27.682 15.002c-9.228 1.921-15.151 10.959-13.23 20.187 1.652 7.935 8.657 13.613 16.762 13.588 1.193.001 2.383-.125 3.55-.375a89.2051 89.2051 0 0044.732-24.269l115.576-115.558c34.95-34.926 34.97-91.571.045-126.522z" />
                      <path d="M290.702 206.142c-2.559-9.072-11.988-14.352-21.06-11.793s-14.352 11.988-11.793 21.06c5.388 19.271-.018 39.95-14.148 54.118L128.125 385.103c-21.608 21.613-56.646 21.617-78.259.008-21.613-21.608-21.617-56.646-.009-78.259l115.576-115.593a55.0831 55.0831 0 0127.648-15.002c9.243-1.849 15.237-10.84 13.388-20.082s-10.84-15.237-20.082-13.388c-.113.023-.225.046-.337.071a89.3422 89.3422 0 00-44.749 24.269L25.725 282.703c-34.676 35.211-34.242 91.865.969 126.541 34.827 34.297 90.731 34.301 125.563.008l115.575-115.593c22.868-22.903 31.61-56.356 22.87-87.517z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Linkaudit
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Hier überprüfe ich dein aktuellen Linkprofil auf schädliche
                    Backlinks und Backlinkspotenziale, wie z.b. Broken
                    Backlinks.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0 p-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103 bg-white object-center rounded-lg overflow-hidden shadow-lg hover:shadow-2xl">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-800 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      viewBox="0 0 512 512"
                      stroke="currentColor"
                    >
                      <defs />
                      <path d="M222.25 172.143L214.401 217l41.099-21.179L296.599 217l-7.849-44.857L322 140.374l-45.95-6.545-20.55-40.813-20.55 40.813-45.95 6.545z" />
                      <path d="M418.913 39c.064-6.437.087-12.779.087-19V0H91v20c0 6.221.023 12.563.087 19H0v20c0 51.185 13.454 94.431 41.131 132.212 23.498 32.075 53.051 54.588 79.125 74.45 16.226 12.36 30.689 23.394 40.918 34.548 20.079 26.582 44.855 49.663 73.826 68.778V402h-10c-49.626 0-90 40.374-90 90v20h240v-20c0-49.626-40.374-90-90-90h-10v-33.012c28.919-19.081 53.66-42.114 73.72-68.636 10.331-11.24 25.087-22.366 41.65-34.838 26.354-19.844 56.224-42.336 79.987-74.374C498.379 153.362 512 110.139 512 59V39h-93.087zM40.86 79h51.338c1.935 39.112 6.886 80.589 19.317 121.626.987 3.258 2.028 6.476 3.096 9.674C78.983 180.355 46.493 142.702 40.86 79zM285 442c20.464 0 38.095 12.355 45.825 30h-151.65c7.73-17.645 25.361-30 45.825-30h60zm33.929-168.313c-3.831 4.189-7.347 8.507-10.435 13.02l.082.056c-14.644 17.261-32.291 33.232-53.576 47.498-21.285-14.266-38.932-30.236-53.576-47.499l.082-.056c-3.131-4.576-6.69-8.952-10.567-13.197C140.933 205.571 132.174 120.385 131.122 40h247.756c-1.053 80.456-9.828 165.719-59.949 233.687zm76.149-62.461c1.18-3.5 2.324-7.028 3.406-10.6 12.432-41.037 17.383-82.514 19.317-121.626h53.323c-5.79 64.352-39.454 102.106-76.046 132.226z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Wettbewerbsanalyse
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Ich analysiere, wer deine Hauptkonkurrenten in den
                    Google-Suchergebnissen sind und wo deren Stärken und deine
                    Schwächen liegen.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default ServicesOverview;
