import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";

const CTASection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "teaser.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = [
        data.desktop.childImageSharp.fluid,
        `linear-gradient(to right, rgba(45, 81, 125, .8),rgba(45, 81, 125, .8) 70%)`,
      ].reverse();

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          loading="eager"
          fadeIn={false}
        >
          <div className="h-0 lg:h-64 md:absolute md:left-0 md:h-full md:w-1/2"></div>
          <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-orange-600 sm:text-4xl sm:leading-10">
                Rankings verbessern?
                <br />
                <span className="text-white">Keine Zeit verlieren.</span>
              </h2>
              <div className="mt-8">
                <div className="w-full md:w-auto flex md:flex-none rounded-md shadow">
                  <a
                    href="#contactform"
                    className="w-full md:w-auto flex md:flex-none items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out shadow-lg"
                  >
                    <svg
                      className="-ml-1 mr-2 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
                    </svg>
                    Jetzt Kontakt aufnehmen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

CTASection.propTypes = {
  className: PropTypes.string.isRequired,
};

export default CTASection;
