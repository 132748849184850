import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function AboutMe() {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "Layer-1-300x300.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 96, height: 96) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  const imageData = data.desktop.childImageSharp.fixed;

  return (
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-12 mx-auto flex flex-col">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="text-base leading-6 mb-4 text-indigo-600 font-semibold tracking-wide uppercase">
              Wissenswertes
            </p>
            <h2 className="text-3xl mb-2 leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Über Mich
            </h2>
          </div>
          <div className="flex flex-col sm:flex-row mt-10">
            <div className="sm:w-1/3 lg:text-center sm:pr-8 sm:py-8">
              <div className="w-24 h-24 rounded-full inline-flex lg:items-center lg:justify-center bg-gray-200 text-gray-400">
                <Img
                  className="rounded-full"
                  fixed={imageData}
                  loading="lazy"
                  fadeIn={false}
                />
              </div>
              <div className="flex flex-col lg:items-center lg:text-center lg:justify-center">
                <h2 className="font-medium title-font mt-4 text-gray-900 text-2xl">
                  Lukas Euler
                </h2>
                <p className="text-base text-gray-600">SEO-Experte & Berater</p>
                <div className="mt-4 flex-shrink-0 sm:mt-0">
                  <div className="flex overflow-hidden lg:justify-center mt-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 px-4"
                      viewBox="0 0 24 24"
                    >
                      <defs />
                      <path d="M14.887 24l-5.324-9.667L17.633 0h4.933l-8.069 14.333L19.767 24h-4.88zM7.596 5H2.657l2.768 4.744L1.31 17h4.914l4.117-7.271L7.596 5z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 px-4"
                      viewBox="0 0 24 24"
                    >
                      <defs />
                      <path d="M0 0v24h24V0H0zm8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.397-2.586 7-2.777 7 2.476V19z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-300 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 sm:text-left">
              <p className="leading-relaxed text-lg mb-4">
                Mein Name ist Lukas Euler und ich bin leidenschaftlicher Online
                Marketer und Web-Entwickler. Durch meine mehrjährige Erfahrung
                als SEO Manager im Agenturleben habe ich meine Expertise in
                Suchmaschinenoptimierung – SEO und der Websitenoptimierung
                täglich geschärft und weiß worauf es ankommt, um deine Seite in
                den Google-SERPs nach oben zu bringen.
              </p>
              <p className="leading-relaxed text-lg mb-4">
                Seit 2019 betreue ich Kunden auch als selbständiger SEO
                Spezialist und Freelancer im Bereich Suchmaschinenoptimierung
                und will auch dir helfen, dein lokales Business, deine
                Dienstleistung und deinen Online Shop langfristig und nachhaltig
                zu verbessern.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutMe;
