import React from "react";
import { useForm } from "react-hook-form";
import { navigate } from "@reach/router"  

function Contact() {
  const { register, handleSubmit, errors } = useForm();
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const onSubmit = (data) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "Vorname": data.Vorname,
        "Nachname": data.Nachname,
        "email": data.email,
        "Telefon": data.Telefon,
        "website": data.website,
        "Nachricht": data.Nachricht,
      }),
    })
      .then(() => navigate('/thank-you'))
      .catch((error) => alert(error));

  };

  return (
    <div className="relative py-12 bg-blue-800">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 px-4 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12">
          <div className="lg:mx-auto">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
              Wie kann ich dich unterstützen?
            </h2>
            <p className="mt-3 text-lg leading-6 text-white">
              Wenn du Fragen zu meinem Angebot hast oder dich direkt mit einer
              Anfrage an mich wenden möchtest, fülle am besten das Formular aus
              und ich melde mich schnellstmöglich bei dir.
            </p>
            <dl className="mt-8 text-base leading-6 text-white">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p>Pestalozzistrasse 25</p>
                  <p>22305 Hamburg</p>
                  <p>Deutschland</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Telefon</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <span className="ml-3">+49 159 06800166</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">E-Mail</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="ml-3">seo@lukaseuler.de</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div
          id="contactform"
          className="px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12"
        >
          <div className="lg:mx-auto lg:max-w-none mt-8 md:mt-0">
            <form
              name="contact"
              data-netlify="true"
              method="POST"
              action="/thank-you"
              netlify-honeypot="bot-field"
              className="grid grid-cols-1 row-gap-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-sm font-medium leading-5 text-white mb-2"
                    htmlFor="grid-first-name"
                  >
                    Vorname
                  </label>
                  <input
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    id="grid-first-name"
                    type="text"
                    name="Vorname"
                    placeholder="Max"
                    ref={register}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block text-sm font-medium leading-5 text-white mb-2"
                    htmlFor="grid-last-name"
                  >
                    Nachname
                  </label>
                  <input
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    id="grid-last-name"
                    type="text"
                    placeholder="Mustermann"
                    name="Nachname"
                    ref={register}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 text-white mb-2"
                  >
                    E-Mail*
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                      </svg>
                    </div>
                    <input
                      id="email"
                      name="email"
                      className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="max@mustermann.de"
                      ref={register({
                        required: true,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                  </div>
                  {errors.email && (
                    <p
                      className="mt-2 text-sm text-red-600 flex"
                      id="email-error"
                    >
                      <svg
                        className="ml-2 w-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <defs />
                        <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
                      </svg>
                      Bitte eine korrekte E-Mail angeben
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-5 text-white mb-2"
                  >
                    Telefon
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      id="phone"
                      name="Telefon"
                      className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                      placeholder="+49 40 12345678"
                      ref={register}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="website"
                  className="block text-sm font-medium leading-5 text-white mb-2"
                >
                  Website*
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-white text-gray-500 sm:text-sm">
                    https://
                  </span>
                  <input
                    id="website"
                    name="website"
                    className="form-input flex-1 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm sm:leading-5"
                    placeholder="www.example.com"
                    ref={register({ required: true })}
                  />
                </div>
                {errors.website && (
                  <p
                    className="mt-2 text-sm text-red-600 flex"
                    id="website-error"
                  >
                    <svg
                      className="ml-2 w-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <defs />
                      <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
                    </svg>
                    Bitte eine Website angeben
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium leading-5 text-white mb-2"
                >
                  Nachricht
                </label>
                <div className="relative rounded-md shadow-sm">
                  <textarea
                    id="message"
                    rows="6"
                    className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                    placeholder="Hier kannst du mir dein Anliegen detalliert schildern..."
                    name="Nachricht"
                    ref={register}
                  ></textarea>
                </div>
              </div>
              <div className="">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out shadow-lg"
                  >
                    Anfrage Abschicken
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
